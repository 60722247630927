import "../styles/Aboutus.css";
import telsem from "../assets/telsem.jpg";
const Aboutus = () => {
  return (
    <div className="about">
      <div className="about-left">
        <img src={telsem} alt="" className="about-img"/>
      </div>
      <div className="about-right">
        <h3>ABOUT TELSEM</h3>
        <h2>Design solutions you can count on</h2>
        <p>
          TELSEM Consulting Architects and Engineers PLC is established at the
          beginning of 2017 G.C in the form of a private limited company. 
          </p>
          <p>At the
          beginning, the company was formed to execute construction design,
          supervision, and contract administration of all types of construction
          projects including building, road, and water projects. After a while,
          the company has organized a Geotechnical & Foundation Investigation
          and Laboratory Testing establishment.</p> 
          <p>EYOEL GETACHEW (CEO) has acquired a separate trade license for Geotechnical 
          Services from
          <strong> Addis Ababa City Administration Trade and construction ministry.</strong>
        </p>
      </div>
    </div>
  );
};

export default Aboutus;
