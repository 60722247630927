import "../styles/Projects.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import arrow from "../assets/arrow.png";

const File = ({ data, setNavbarVisible }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showMore, setShowMore] = useState(false); // State to manage visibility

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
    setNavbarVisible(false); // Hide navbar
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
    setNavbarVisible(true); // Show navbar
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="projectss">
      {data.slice(0, showMore ? data.length : 1).map((item) => (
        <div className="dprojects" key={item.id}>
          <div className="pservice">
            <h3>{item.siteName}</h3>
            <h2>{item.model}</h2>
            <p>{item.location}</p>
          </div>
          <Slider className="pservices" {...settings}>
            {item.img.map((image, imgIndex) => (
              <div className="service" key={imgIndex}>
                <img
                  src={image}
                  alt={`Site ${item.id} - Image ${imgIndex + 1}`}
                  onClick={() => openModal(image)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
          </Slider>
        </div>
      ))}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
      >
        <button className="modal-close-button" onClick={closeModal}>
          Close
        </button>
        {selectedImage && (
          <img src={selectedImage} alt="Selected" style={{ width: "100%" }} />
        )}
      </Modal>

      <div className="show-more-container">
        <button onClick={toggleShowMore} className="btn">
          {showMore ? "Show Less" : "Show More"} <img src={arrow} alt="" />
        </button>
      </div>
    </div>
  );
};

export default File;
