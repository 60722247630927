import '../styles/Footer.css'
const Footer = () => {
    
    
    return ( 
<div className="footer">
    <p>© 2024 Telsem Construction Consulting Plc. All rights reseved.</p>
    <ul>
        <li>Terms Of service</li>
        <li>Privacy Policy</li>
        
    </ul>
</div>

     );
}
 
export default Footer;