import React, { useState } from "react";
import File from "./Files.js";
import Navbar from "./Navbar";
import jimma from "../assets/jimma (1).jpg";
import jimma2 from "../assets/jimma (2).jpg";
import jimma3 from "../assets/jimma (3).jpg";
import jimma4 from "../assets/jimma (4).jpg";
import jimma5 from "../assets/jimma (5).jpg";

import g201 from "../assets/g20 (1).jpg";
import g202 from "../assets/g20 (2).jpg";
import g203 from "../assets/g20 (3).jpg";
import g204 from "../assets/g20 (4).jpg";

const Projects = () => {
  const [navbarVisible, setNavbarVisible] = useState(true);

  const sites = [
    {
      siteName: "Ato menilik",
      model:'G+20 Mixed Building',
      img: [g201,g202,g203,g204],
      location: "Addis Ababa, Ethiopia",
      id: 1,
    },
    {
      siteName: "Jimma Central Hotel",
      model:'G+5 Hotel',
      img: [jimma, jimma2, jimma3, jimma4, jimma5],
      location: "Central Jimma",
      id: 2,
    },
  ];

  return (
    <div className="Projects">
      {/* {navbarVisible && <Navbar />} */}
      <File data={sites} setNavbarVisible={setNavbarVisible} />
    </div>
  );
};

export default Projects;
