import "../styles/Navbar.css";
import logo from "../assets/conhelmet.png";
import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import menuicon from "../assets/menuicon.png";

const Navbar = () => {
  const [stick, setStick] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [iconChange, setIconChange] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 60 ? setStick(true) : setStick(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const tIcon = () => {
    setIconChange(!iconChange);
  };

  const handleLinkClick = () => {
    setMobileMenu(false);
    setIconChange(false);
  };

  return (
    <nav className="ncontainer">
      <div className="imgContainer">
        <img className="logo" src={logo} alt="Logo" />
        <h2>Telsem Consulting Plc</h2>
      </div>
      <ul className={mobileMenu ? "" : "hide-mobile-menu"}>
        <li>
          <Link to="header" smooth={true} offset={0} duration={500} onClick={handleLinkClick}>
            Home
          </Link>
        </li>
        <li>
          <Link to="about" smooth={true} offset={-150} duration={500} onClick={handleLinkClick}>
            About Us
          </Link>
        </li>
        <li>
          <Link to="Projects" smooth={true} offset={-150} duration={500} onClick={handleLinkClick}>
            Projects
          </Link>
        </li>
        <li>
          <Link to="testimonial" smooth={true} offset={-200} duration={500} onClick={handleLinkClick}>
            Testimonial
          </Link>
        </li>
        <li>
          <Link to="contact" smooth={true} offset={-260} duration={500} onClick={handleLinkClick}>
            Contact Us
          </Link>
        </li>
      </ul>
      <img
        src={menuicon}
        onClick={() => {
          toggleMenu();
          tIcon();
        }}
        alt="menu-icn"
        className={`menu-icon ${iconChange ? "menu-icont" : ""}`}
      />
    </nav>
  );
};

export default Navbar;
