import "../styles/Header.css";
import arrow from "../assets/arrow.png";

const Header = () => {
  return (
    <div className="header container">
      <div className="header-text">
        <h1>
          Take the construction design, super vision and contract administration
          work one step closer to perfection by giving a world-class consultancy
          service.
        </h1>
        <p>
          Committed to customer satisfaction by providing state-of-the-art
          engineering consultancy service that is efficient, affordable,
          aesthetically appealing, functionally comprehensive, durable, and most
          of all which gives great attention to the peoples who use them.
        </p>
      </div>
    </div>
  );
};

export default Header;
