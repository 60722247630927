import "../styles/Services.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import soiltest from "../assets/soiltest.jpg";
import archi from "../assets/Archi.jpg";
import topography from "../assets/topography.jpg";
import town from "../assets/town.jpg";
import React from "react";
import Slider from "react-slick";

const Services = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    // {/* <div className="services"> */}
    <Slider className="sservices" {...settings}>
      <div className="sservice">
        <img src={soiltest} alt="" />
        <div className="caption">
          <h3>Soil Testing</h3>
        </div>
      </div>
      <div className="sservice">
        <img src={archi} alt="" />
        <div className="caption">
          <h3>Architectural Design</h3>
        </div>
      </div>
      <div className="sservice">
        <img src={topography} alt="" />
        <div className="caption">
          <h3>Topographical Design</h3>
        </div>
      </div>
      <div className="sservice">
        <img src={town} alt="" />
        <div className="caption">
          <h3>Town Planning</h3>
        </div>
      </div>
      {/* <div className="sservice">
        <img src={town} alt="" />
        <div className="caption">
          <h3>Soil Testing</h3>
        </div>
      </div> */}
    </Slider>
    // {/* </div> */}
  );
};

export default Services;
