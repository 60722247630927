import '../styles/Tittle.css'
const Tittles = ({tittle,subTittle}) => {
    return ( 
        <div className="tittle">
            <h2>{subTittle}</h2>
            <h1>{tittle}</h1>
        </div>
     );
}
 
export default Tittles;