import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Services from "./components/Services";
import Tittles from "./components/Tittle";
import Aboutus from "./components/Aboutus";
import Projects from "./components/Projects";
import Testimonial from "./components/Testimonial";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <div className="container">
        <Aboutus />
        <Tittles
          subTittle="Our Services"
          tittle="Discover What We Are Capable Of"
        />
        <Services />
        <Tittles subTittle="Projects" tittle="Watch What We Are Capable Of" />
        <Projects />
        <Tittles tittle="Read What People Say" subTittle="Testimonials" />
        <Testimonial />
        <Tittles tittle="Get In Touch" subTittle="Contact Us" />
        <Contact />
        <Footer/>
      </div>
    </div>
  );
}

export default App;
