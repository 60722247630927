import "../styles/Contact.css";
import msgIcn from "../assets/message.png";
import phnIcn from "../assets/phone.png";
import locn from "../assets/location.png";
import email from "../assets/email.png";
import tgram from "../assets/telegram.png";
import { useState } from "react";
const Contact = () => {
  
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "b420d6d5-3f66-42aa-a1fc-0a47fa43d610");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  
  
  return (
    <div className="contact">
      <div className="contact-col">
        <h3>
          Send Us A Message <img src={msgIcn} alt="" />
        </h3>
        <p>
          We’d love to hear from you! If you have any questions, comments, or
          need assistance, please feel free to reach us. Our team is here to
          help and will get back to you as soon as possible.
        </p>
        <ul>
          <li>
            <img src={email} alt="" />
            <a href="mailto:Telsemplc406@gmail.com">Telsemplc406@gmail.com</a>
          </li>
          <li>
            {" "}
            <a href="tel:+251-9-10-31-14-31">
              <img src={phnIcn} alt="" />
              +251-9-10-31-14-31
            </a>
          </li>
          <li>
            <a href="https://t.me/telsembetel">
              <img src={tgram} alt="" />
              @Telsembetel
            </a>
          </li>
          <li>
            {" "}
            <img src={locn} alt="" />
            Metebaber Building, 4th floor Office Number 406
          </li>
        </ul>
      </div>
      <div className="contact-col">
        <form onSubmit={onSubmit}>
          <label>Your Name</label>
          <input
            type="text"
            name="name"
            placeholder="Enter Your name"
            required
          />
          <label>Phone Number</label>
          <input
            type="tel"
            name="phone"
            placeholder="Enter Your Mobile Number"
            required
          />
          <label>Write Your message here</label>
          <textarea
            name="message"
            placeholder="Enter a text"
            rows="6"
            required
          ></textarea>
          <button type="submit" className="btn dark-btn">
            Submit
          </button>
        </form>
        <span>{result}</span>
      </div>
    </div>
  );
};

export default Contact;
